import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Box, FormControl, Select, Button, MenuItem, InputLabel } from '@mui/material';
import { AuthContext } from '../../../../services/AuthContext';
import BarraLateral from '../../TemplatesEstrutura/BarraLateral';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import Chart from 'chart.js/auto';
import './dashOrcamento.css';
import axios from 'axios';  // Import axios para fazer a requisição à API

dayjs.locale('pt-br');

const DashOrcamento = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const [mes, setMes] = useState('');
    const [ano, setAno] = useState('');
    const [gastos, setGastos] = useState([]);
    const [dividas, setDividas] = useState([]);
    const [chartInstance, setChartInstance] = useState(null);

    const updateDateFromStorage = () => {
        const storedMes = localStorage.getItem('mes');
        const storedAno = localStorage.getItem('ano');

        if (storedMes && storedAno) {
            setMes(storedMes);
            setAno(storedAno);
        } else {
            const today = dayjs();
            const currentMes = today.format('MM');
            const currentAno = today.format('YYYY');

            setMes(currentMes);
            setAno(currentAno);
            localStorage.setItem('mes', currentMes);
            localStorage.setItem('ano', currentAno);
        }
    };

    const handleMesChange = (event) => {
        const newMes = event.target.value;
        setMes(newMes);
        localStorage.setItem('mes', newMes);
    };

    const handleAnoChange = (event) => {
        const newAno = event.target.value;
        setAno(newAno);
        localStorage.setItem('ano', newAno);
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        } else {
            updateDateFromStorage();
        }
    }, [isAuthenticated, navigate]);

    // Fetch Gastos e Dívidas from API
    useEffect(() => {
        const token = localStorage.getItem('token');  // Obtém o token do localStorage

        const fetchGastos = async () => {
            try {
                const response = await axios.get(`https://organizeseenewersion-2b835fcbcece.herokuapp.com/api/gastos/${ano}/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,  // Usa o token para autenticação
                    },
                });
                
                setGastos(response.data);
            } catch (error) {
                console.error('Erro ao buscar dados de gastos', error);
            }
        };

        const fetchDividas = async () => {
            try {
                const response = await axios.get(`https://organizeseenewersion-2b835fcbcece.herokuapp.com/api/dividas/${ano}/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,  // Usa o token para autenticação
                    },
                });

                setDividas(response.data);
            } catch (error) {
                console.error('Erro ao buscar dados de dívidas', error);
            }
        };


        if (mes && ano) {
            fetchGastos();
            fetchDividas();
        }
    }, [mes, ano]);

    // Renderização do gráfico
// useEffect para renderização do gráfico
useEffect(() => {
    const ctx = document.getElementById('orcamentoChart').getContext('2d');

    // Destrói o gráfico anterior, se existir
    if (chartInstance) {
        chartInstance.destroy();
    }

    // Cria uma nova instância do gráfico
    const newChartInstance = new Chart(ctx, {
        type: 'line',
        data: {
            labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            datasets: [
                {
                    label: 'Gastos',
                    data: gastos.map(gasto => gasto.valor_mensal),  // Alimentar o gráfico com os dados reais de gastos
                    borderColor: '#42A5F5',
                    fill: false,
                },
                {
                    label: 'Dívidas',
                    data: dividas.map(divida => divida.divida_total),  // Alimentar o gráfico com os dados reais de dívidas
                    borderColor: '#FF7043',
                    fill: false,
                }
            ],
        },
        options: {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Mês',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Valor (R$)',
                    },
                },
            },
        },
    });

    setChartInstance(newChartInstance);

    // Cleanup function para destruir o gráfico ao desmontar o componente ou atualizar o gráfico
    return () => {
        if (newChartInstance) {
            newChartInstance.destroy();
        }
    };

    // Incluindo apenas as dependências necessárias, evitando o loop infinito
}, [mes, ano, gastos, dividas]);

    if (!isAuthenticated) return <div>Carregando...</div>;

    return (
        <Box sx={{ display: 'flex', width: '95vw', height: '100vh', overflow: 'hidden', bgcolor: '#1e1e1e', color: 'white' }}>
            {/* Ajuste da Barra Lateral */}
            <Box sx={{ width: '140px', flexShrink: 0, height: '100vh', overflowY: 'auto', bgcolor: '#1e1e1e' }}>
                <BarraLateral />
            </Box>
    
            {/* Área de Conteúdo Principal */}
            <Box sx={{ flexGrow: 1, p: 4, height: '100vh', overflowY: 'auto', bgcolor: '#1e1e1e' }}>
                <Container disableGutters maxWidth={false}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#1e1e1e' }}>
                        <Typography variant="h4" sx={{ color: 'white' }}>Meu Orçamento</Typography>
                        
                        <Box sx={{ display: 'flex', gap: 2, bgcolor: '#1e1e1e' }}>
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel sx={{ color: 'white' }}>Mês</InputLabel>
                                <Select
                                    value={mes}
                                    onChange={handleMesChange}
                                    label="Mês"
                                    sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                                >
                                    {[...Array(12).keys()].map(i => (
                                        <MenuItem key={i + 1} value={String(i + 1).padStart(2, '0')}>
                                            {dayjs().month(i).format('MMMM').charAt(0).toUpperCase() + dayjs().month(i).format('MMMM').slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
    
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel sx={{ color: 'white' }}>Ano</InputLabel>
                                <Select
                                    value={ano}
                                    onChange={handleAnoChange}
                                    label="Ano"
                                    sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                                >
                                    {[...Array(5).keys()].map(i => (
                                        <MenuItem key={i} value={String(dayjs().year() - i)}>
                                            {dayjs().year() - i}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Container>
    
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 3, mb: 3, bgcolor: '#1e1e1e'  }}>
                    {/* Botões de Navegação */}
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashOrcamento')}>Meu Orçamento</Button>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashCusto')}>Custo</Button>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashDividas')}>Dívidas</Button>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashMetas')}>Metas</Button>
                </Box>
    
                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2, bgcolor: '#1e1e1e', width: '96%' }}>
                    <canvas id="orcamentoChart" style={{ maxHeight: '320px', color: 'white' }} />
                </Box>
            </Box>
        </Box>
    );
    
};

export default DashOrcamento;
