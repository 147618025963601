import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Box, Grid, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { AuthContext } from '../../../../services/AuthContext';
import BarraLateral from '../../TemplatesEstrutura/BarraLateral';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import Chart from 'chart.js/auto';
import './dashMetas.css';  // Importa o arquivo CSS

dayjs.locale('pt-br');

const DashMetas = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const [mes, setMes] = useState('');
    const [ano, setAno] = useState('');
    const [sliderValues, setSliderValues] = useState({
        custoFixo: 0,
        conforto: 0,
        metas: 0,
        prazeres: 0,
        liberdadeFinanceira: 0,
        conhecimento: 0,
    });

    const [error, setError] = useState('');
    const [total, setTotal] = useState(0);

    const updateDateFromStorage = () => {
        const storedMes = localStorage.getItem('mes');
        const storedAno = localStorage.getItem('ano');

        if (storedMes && storedAno) {
            setMes(storedMes);
            setAno(storedAno);
        } else {
            const today = dayjs();
            const currentMes = today.format('MM');
            const currentAno = today.format('YYYY');

            setMes(currentMes);
            setAno(currentAno);
            localStorage.setItem('mes', currentMes);
            localStorage.setItem('ano', currentAno);
        }
    };

    const handleMesChange = (event) => {
        const newMes = event.target.value;
        setMes(newMes);
        localStorage.setItem('mes', newMes);
    };

    const handleAnoChange = (event) => {
        const newAno = event.target.value;
        setAno(newAno);
        localStorage.setItem('ano', newAno);
    };

    const handleSliderChange = (event) => {
        const { name, value } = event.target;

        const newValues = { ...sliderValues, [name]: value };
        const total = Object.values(newValues).reduce((acc, curr) => acc + parseInt(curr), 0);

        if (total > 100) {
            setError('A soma das porcentagens não pode exceder 100%. Ajuste seus valores.');
        } else {
            setError('');
            setSliderValues(newValues);
            setTotal(total);
        }
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        } else {
            updateDateFromStorage();
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        const ctx = document.getElementById('allocationChart').getContext('2d');
        let allocationChart;

        if (allocationChart) {
            allocationChart.destroy();
        }

        allocationChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ['Custo Fixo', 'Conforto', 'Metas', 'Prazeres', 'Liberdade Financeira', 'Conhecimento'],
                datasets: [{
                    label: 'Distribuição das Metas',
                    data: [
                        sliderValues.custoFixo,
                        sliderValues.conforto,
                        sliderValues.metas,
                        sliderValues.prazeres,
                        sliderValues.liberdadeFinanceira,
                        sliderValues.conhecimento,
                    ],
                    backgroundColor: [
                        '#E6E6FA',
                        '#EE82EE',
                        '#9370DB',
                        '#00BFFF',
                        '#00FA9A',
                        '#FF6347',
                    ],
                }],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    }
                }
            },
        });

        return () => {
            if (allocationChart) {
                allocationChart.destroy();
            }
        };
    }, [sliderValues]);

    if (!isAuthenticated) return <div>Carregando...</div>;

    return (
        <Box sx={{ display: 'flex',  height: '100vh', overflow: 'hidden', bgcolor: '#1e1e1e', color: 'white' }}>
            {/* Ajuste da Barra Lateral */}
            <Box sx={{ width: '140px', flexShrink: 0, height: '100vh', overflowY: 'auto', bgcolor: '#1e1e1e' }}>
                <BarraLateral />
            </Box>
    
            {/* Área de Conteúdo Principal */}
            <Box sx={{ flexGrow: 1, p: 4, width: '79.4vw', height: '100vh', bgcolor: '#1e1e1e' }}>
                <Container disableGutters maxWidth={false}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#1e1e1e' }}>
                        <Typography variant="h4" sx={{ color: 'white' }}>Meu Orçamento</Typography>
                        
                        <Box sx={{ display: 'flex', gap: 2, bgcolor: '#1e1e1e' }}>
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel sx={{ color: 'white' }}>Mês</InputLabel>
                                <Select
                                    value={mes}
                                    onChange={handleMesChange}
                                    label="Mês"
                                    sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                                >
                                    {[...Array(12).keys()].map(i => (
                                        <MenuItem key={i + 1} value={String(i + 1).padStart(2, '0')}>
                                            {dayjs().month(i).format('MMMM').charAt(0).toUpperCase() + dayjs().month(i).format('MMMM').slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
    
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel sx={{ color: 'white' }}>Ano</InputLabel>
                                <Select
                                    value={ano}
                                    onChange={handleAnoChange}
                                    label="Ano"
                                    sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                                >
                                    {[...Array(5).keys()].map(i => (
                                        <MenuItem key={i} value={String(dayjs().year() - i)}>
                                            {dayjs().year() - i}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Container>
    
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 3, mb: 3, bgcolor: '#1e1e1e'  }}>
                    {/* Botões de Navegação */}
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashOrcamento')}>Meu Orçamento</Button>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashCusto')}>Custo</Button>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashDividas')}>Dívidas</Button>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashMetas')}>Metas</Button>
                </Box>


                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ bgcolor: '#1e1e1e', p: 2, borderRadius: 2, height: '100%' }}>
                        <Typography variant="h6" sx={{ color: 'white' }}>
                            Metas
                        </Typography>
                                {error && (
                                    <Typography variant="body1" sx={{ color: 'red', mb: 1 }}>
                                        {error}
                                    </Typography>
                                )}
                                <canvas id="allocationChart" style={{ maxHeight: '400px', minHeight: '400' }}></canvas>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                                
                        <Box sx={{ bgcolor: '#1e1e1e', p: 2, borderRadius: 2, height: '100%' }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', color: 'white' }}>
                                Alocação de Metas
                            </Typography>
                            {[
                                { name: 'custoFixo', label: 'Custo Fixo' },
                                { name: 'conforto', label: 'Conforto' },
                                { name: 'metas', label: 'Metas' },
                                { name: 'prazeres', label: 'Prazeres' },
                                { name: 'liberdadeFinanceira', label: 'Liberdade Financeira' },
                                { name: 'conhecimento', label: 'Conhecimento' },
                            ].map(({ name, label }) => (
                                <div className="slider-container" key={name}>
                                    <span className="label">{label}:</span>
                                    <input
                                        type="range"
                                        name={name}
                                        value={sliderValues[name]}
                                        onChange={handleSliderChange}
                                        className="slider"
                                        min="0"
                                        max="100"
                                    />
                                    <span>{sliderValues[name]}%</span>
                                </div>
                            ))}
                            <div className="total">
                                <Typography variant="body1" sx={{ fontSize: '1.2rem',color: 'white' }}>Total % {total}</Typography>
                            </div>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ bgcolor: 'green', mt: 2 }}
                            >
                                Atualizar
                            </Button>
                        </Box>

                    </Grid>

                </Grid>



            </Box>
        </Box>
    );
    
};

export default DashMetas;