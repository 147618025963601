import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
//Paginas teste > Descartar e testes.
import DadosPublicos from './components/Inicia/DadosPublicos/DadosPublicos';

//Pagina inicial e login
import Home from './components/Inicia/page/Home';
import Cadastro from './components/Inicia/Cadastro/Cadastro';
import Login from './components/Inicia/Login/Login';

//Parte de Orçamento domestico (Lote 1)
import DashCusto from './components/Sistema/OrcamentoDomestico/Custo/dashCusto';
import DashDividas from './components/Sistema/OrcamentoDomestico/Dividas/dashDividas';
import DashMetas from './components/Sistema/OrcamentoDomestico/Metas/dashMetas';
import DashOrcamento from './components/Sistema/OrcamentoDomestico/OrcamentoDomestico/dashOrcamento';
// import DashPatrimonio from './components/Sistema/OrcamentoDomestico/Patrimonio/dashPatrimonio';

//Parte de Investimentos (Lote 2)
import Comparativos from './components/Sistema/Investimentos/Comparativos/comparativos';
import Cripto from './components/Sistema/Investimentos/Cripto/Cripto';
import Investimentos from './components/Sistema/Investimentos/Investimentos/investimentos';
import Importar from './components/Sistema/Investimentos/Importar/importar';
import Metas from './components/Sistema/Investimentos/Metas/InvestimentoMetas';
import Patrimonio from './components/Sistema/Investimentos/Patrimonio/dashPatrimonio';


//Parte de Indicadores (Lote 3)
import IndicadoresEconomicos from './components/Sistema/Mercados/IndicadoresEconomicos/indicadoresEconomicos';
import ListaDeDesejo from './components/Sistema/Mercados/ListaDeDesejo/listaDeDesejo';
import Analiseticker from './components/Sistema/Mercados/Analise/analiseticker';
import CalculadoraFinanceira from './components/Sistema/Mercados/CalculadoraFinanceira/calculadoraFinanceira';

//Parte de Configuracoes (Lote 3)
import Configuracoes from './components/Sistema/configuracoes'; // Agora é exportado como default


//autenticações e validações com backend
import { AuthProvider } from './services/AuthContext'; // Envolve o app com AuthProvider para as rotas protegidas
import PrivateRoute from './components/PrivateRoute'; // Importa o componente de rotas protegidas

// Páginas públicas e protegidas
function App() {
  return (
    <Router>
      <AuthProvider> {/* Mover AuthProvider para dentro de Router */}
        <div className="App">
          <Routes>
            {/* Páginas públicas */}
            <Route path="/" element={<Home />} /> 
            <Route path="/login" element={<Login />} />
            <Route path="/cadastro" element={<Cadastro />} />
            <Route path="/dados-publicos" element={<DadosPublicos />} />

            {/* Páginas protegidas - Orçamento domestico*/}
            <Route path="/dashCusto" element={<PrivateRoute element={<DashCusto />} />} />
            <Route path="/dashMetas" element={<PrivateRoute element={<DashMetas />} />} />
            <Route path="/dashDividas" element={<PrivateRoute element={<DashDividas />} />} />
            <Route path="/dashOrcamento" element={<PrivateRoute element={<DashOrcamento />} />} />
            {/* <Route path="/dashPatrimonio" element={<PrivateRoute element={<DashPatrimonio />} />} /> */}


            {/* Páginas protegidas - Investimentos*/}
            <Route path="/comparativos" element={<PrivateRoute element={<Comparativos />} />} />
            <Route path="/cripto" element={<PrivateRoute element={<Cripto />} />} />
            <Route path="/investimentos" element={<PrivateRoute element={<Investimentos />} />} />
            <Route path="/importar" element={<PrivateRoute element={<Importar />} />} />
            <Route path="/metas" element={<PrivateRoute element={<Metas />} />} />
            <Route path="/dashPatrimonio" element={<PrivateRoute element={<Patrimonio />} />} />


            {/* Páginas protegidas - Mercado*/}
            <Route path="/indicadoresEconomicos" element={<PrivateRoute element={<IndicadoresEconomicos />} />} />
            <Route path="/listaDeDesejo" element={<PrivateRoute element={<ListaDeDesejo />} />} />
            <Route path="/analiseticker" element={<PrivateRoute element={<Analiseticker />} />} />
            <Route path="/calculadoraFinanceira" element={<PrivateRoute element={<CalculadoraFinanceira />} />} />

            {/* Páginas protegidas - Configurações*/}
            <Route path="/configuracoes" element={<PrivateRoute element={<Configuracoes />} />} />


            
          </Routes>
        </div>
        <SpeedInsights />
      </AuthProvider>
    </Router>
  );
}

export default App;
