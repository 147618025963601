import React, { useState, useContext } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import { AuthContext } from '../../../services/AuthContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './Login.css';

const Login = () => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { setIsAuthenticated } = useContext(AuthContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        try {
            const response = await api.post('https://organizeseenewersion-2b835fcbcece.herokuapp.com/api/login/', formData);
            if (response.status === 200) {
                localStorage.setItem('token', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);
                localStorage.setItem('nomeUsuario', response.data.user.username);
                setIsAuthenticated(true);
                navigate('/dashOrcamento');
            } else {
                setError('Erro desconhecido ao fazer login.');
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.detail) {
                setError(err.response.data.detail);
            } else {
                setError('Erro ao fazer login.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="md" className="login-container">
            <Box className="login-box">
                <Typography component="h1" variant="h5" className="login-title">
                    Login
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate className="login-form">
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Usuário ou e-mail"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={formData.username}
                        onChange={handleChange}
                        className="login-input"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={formData.password}
                        onChange={handleChange}
                        className="login-input"
                        InputProps={{
                            endAdornment: (
                                <Button
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="toggle-password"
                                    
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </Button>
                            ),
                        }}
                    />
                    {error && <Typography color="error" className="login-error">{error}</Typography>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="login-button"
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Entrar'}
                    </Button>
                    <Typography variant="body2" className="login-forgot-password" onClick={() => alert('Recuperação de senha em construção')}>
                        Esqueci a senha
                    </Typography>
                    <Typography variant="body2" className="login-register">
                        <a href="/cadastro">Não tem uma conta? Cadastre-se</a>
                    </Typography>
                </Box>
                <a href="/" className="back-button">Início</a>
            </Box>
        </Container>
    );
};

export default Login;
