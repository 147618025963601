import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material';
import { AuthContext } from '../../../../services/AuthContext';
import BarraLateral from '../../TemplatesEstrutura/BarraLateral';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

dayjs.locale('pt-br');

const DashDividas = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  // Estados para o mês e ano
  const [mes, setMes] = useState('');
  const [ano, setAno] = useState('');
  const [currentCategoria, setCurrentCategoria] = useState('CartaoDeCredito');
  const [dividas, setDividas] = useState([]);
  const [formData, setFormData] = useState({
    descricao: '',
    valor_mensal: '',
    valor_hoje: '',
    taxa_juros: '',
    quantidade_parcelas: '',
  });

  // Função para carregar a data do localStorage ou definir a data atual na primeira vez
  const updateDateFromStorage = () => {
    const storedMes = localStorage.getItem('mes');
    const storedAno = localStorage.getItem('ano');

    if (storedMes && storedAno) {
      setMes(storedMes);
      setAno(storedAno);
    } else {
      // Definir a data atual apenas na primeira vez
      const today = dayjs();
      const currentMes = today.format('MM');
      const currentAno = today.format('YYYY');

      setMes(currentMes);
      setAno(currentAno);
      localStorage.setItem('mes', currentMes);
      localStorage.setItem('ano', currentAno);
    }
  };

  // Funções de mudança para mês e ano
  const handleMesChange = (event) => {
    const newMes = event.target.value;
    setMes(newMes);
    localStorage.setItem('mes', newMes); // Salva no localStorage
  };

  const handleAnoChange = (event) => {
    const newAno = event.target.value;
    setAno(newAno);
    localStorage.setItem('ano', newAno); // Salva no localStorage
  };

// Função para atualizar as dívidas
const atualizarDividas = async () => {
  const token = localStorage.getItem('token'); // Pega o token do localStorage
  try {
    const response = await axios.get('https://organizeseenewersion-2b835fcbcece.herokuapp.com/api/maiores_dividas', {
      params: {
        tipo: currentCategoria,
        mes: mes,
        ano: ano,
      },
      headers: {
        Authorization: `Bearer ${token}`, // Inclui o token no cabeçalho
      },
    });
    setDividas(response.data.maiores_dividas);
  } catch (error) {
    console.error('Erro ao obter as maiores dívidas:', error);
  }
};


  // Função para cadastrar uma nova dívida
  const cadastrarDivida = async () => {
    if (!isAuthenticated) {
      alert('Você precisa estar logado para cadastrar uma dívida.');
      return;
    }

    const data = {
      categoria: currentCategoria, // Corrigido para categoria
      descricao: formData.descricao,
      valor_mensal: parseFloat(formData.valor_mensal),
      valor_hoje: parseFloat(formData.valor_hoje),
      taxa_juros: parseFloat(formData.taxa_juros),
      quantidade_parcelas: parseInt(formData.quantidade_parcelas),
      mes: parseInt(mes),
      ano: parseInt(ano),
    };

    // Recupera o token do localStorage
    const token = localStorage.getItem('token');

    try {
      await axios.post('https://organizeseenewersion-2b835fcbcece.herokuapp.com/api/cadastrar_divida/', data, {
        headers: {
          Authorization: `Bearer ${token}`, // Usa o token armazenado
        },
      });
      
      alert('Dívida cadastrada com sucesso!');
      setFormData({
        descricao: '',
        valor_mensal: '',
        valor_hoje: '',
        taxa_juros: '',
        quantidade_parcelas: '',
      });
      atualizarDividas();
    } catch (error) {
      console.error('Erro ao cadastrar dívida:', error);
      alert('Erro ao cadastrar dívida. Verifique o console para mais detalhes.');
    }
  };

// Função para excluir uma dívida
const excluirDivida = async (id) => {
  if (window.confirm('Tem certeza que deseja excluir esta dívida?')) {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`https://organizeseenewersion-2b835fcbcece.herokuapp.com/api/excluir_divida/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`, // Usa o token armazenado
        },
      });
      alert('Dívida excluída com sucesso!');
      atualizarDividas();
    } catch (error) {
      console.error('Erro ao excluir dívida:', error);
      alert('Erro ao excluir dívida. Verifique o console para mais detalhes.');
    }
  }
};




// Função para formatar valores monetários
const formatarValor = (valor) => {
  if (valor === undefined || valor === null) {
    return 'Não há nada cadastrado no sistema para seu usuário'; // Mensagem quando o valor não está disponível
  }
  return valor.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};


  // Função para alterar a categoria atual
  const showForm = (categoria) => {
    setCurrentCategoria(categoria);
    atualizarDividas();
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      updateDateFromStorage(); // Atualiza a data ao montar o componente
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    atualizarDividas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategoria, mes, ano]);

  if (!isAuthenticated) return <div>Carregando...</div>;

    return (
        <Box sx={{ display: 'flex', width: '95.5vw',  overflow: 'hidden', bgcolor: '#1e1e1e', color: 'white' }}>
            {/* Ajuste da Barra Lateral */}
            <Box sx={{ width: '140px', flexShrink: 0,  overflowY: 'auto', bgcolor: '#1e1e1e' }}>
                <BarraLateral />
            </Box>
    
            {/* Área de Conteúdo Principal */}
            <Box sx={{ flexGrow: 1, p: 4, overflowY: 'auto', bgcolor: '#1e1e1e' }}>
                <Container disableGutters maxWidth={false}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#1e1e1e' }}>
                        <Typography variant="h4" sx={{ color: 'white' }}>Minhas Dividas</Typography>
                        
                        <Box sx={{ display: 'flex', gap: 2, bgcolor: '#1e1e1e' }}>
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel sx={{ color: 'white' }}>Mês</InputLabel>
                                <Select
                                    value={mes}
                                    onChange={handleMesChange}
                                    label="Mês"
                                    sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                                >
                                    {[...Array(12).keys()].map(i => (
                                        <MenuItem key={i + 1} value={String(i + 1).padStart(2, '0')}>
                                            {dayjs().month(i).format('MMMM').charAt(0).toUpperCase() + dayjs().month(i).format('MMMM').slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
    
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel sx={{ color: 'white' }}>Ano</InputLabel>
                                <Select
                                    value={ano}
                                    onChange={handleAnoChange}
                                    label="Ano"
                                    sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                                >
                                    {[...Array(5).keys()].map(i => (
                                        <MenuItem key={i} value={String(dayjs().year() - i)}>
                                            {dayjs().year() - i}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Container>
    
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 3, mb: 3, bgcolor: '#1e1e1e'  }}>
                    {/* Botões de Navegação */}
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashOrcamento')}>Meu Orçamento</Button>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashCusto')}>Custo</Button>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashDividas')}>Dívidas</Button>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashMetas')}>Metas</Button>
                </Box>
    
                <Box sx={{ bgcolor: '#1e1e1e', p: 3, borderRadius: 2 }}>
                <Typography variant="h6" sx={{ color: 'white' }}>
                  <i className="fas fa-balance-scale-left"></i> Dívidas
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: 'white' }}>Descrição</TableCell>
                      <TableCell sx={{ color: 'white' }}>Valor Mensal</TableCell>
                      <TableCell sx={{ color: 'white' }}>Valor Hoje</TableCell>
                      <TableCell sx={{ color: 'white' }}>Dívida Total</TableCell>
                      <TableCell sx={{ color: 'white' }}>QT</TableCell>
                      <TableCell sx={{ color: 'white' }}>Jrs Anual</TableCell>
                      <TableCell sx={{ color: 'white' }}>Juros Mês</TableCell>
                      <TableCell sx={{ color: 'white' }}>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                                    
                  {dividas.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                        <Typography variant="body1" sx={{ color: 'red' }}>
                          Não há dívidas cadastradas para o seu usuário.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    
                    dividas.map((divida) => (
                      <TableRow key={divida.id} sx={{ color: 'white' }}>
                        <TableCell sx={{ color: 'white' }}>{divida.descricao}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{formatarValor(divida.valor_mensal)}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{formatarValor(divida.valor_hoje)}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{formatarValor(divida.divida_total)}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{divida.quantidade_parcelas}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{divida.taxa_juros}%</TableCell>
                        <TableCell sx={{ color: 'white' }}>{formatarValor(divida.juros_mensais)}</TableCell>
                        <TableCell>
                          <IconButton
                            color="error"
                            onClick={() => excluirDivida(divida.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    
                  )}
                </TableBody>

                </Table>
              </Box>

              <Box sx={{ bgcolor: '#1e1e1e', p: 3, borderRadius: 2, mr: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>
                <i className="fas fa-balance-scale-right"></i> {currentCategoria}
              </Typography>

              {/* Botões de Categoria */}
              <Box sx={{ display: 'flex', gap: 2, my: 2, bgcolor: '#1e1e1e',color: 'white' }}>
                <IconButton
                  color={
                    currentCategoria === 'CartaoDeCredito' ? 'primary' : 'default'
                  }
                  onClick={() => showForm('CartaoDeCredito')}
                >
                   <CreditCardIcon sx={{ color: 'white' }} />
                </IconButton>
                <IconButton
                  color={
                    currentCategoria === 'Financiamento' ? 'primary' : 'default'
                  }
                  onClick={() => showForm('Financiamento')}
                >
                  <DirectionsCarIcon  sx={{ color: 'white' }} />
                </IconButton>
                <IconButton
                  color={
                    currentCategoria === 'Emprestimo' ? 'primary' : 'default'
                  }
                  onClick={() => showForm('Emprestimo')}
                >
                  <AttachMoneyIcon  sx={{ color: 'white' }} />
                </IconButton>
                <IconButton
                  color={currentCategoria === 'Outros' ? 'primary' : 'default'}
                  onClick={() => showForm('Outros')}
                >
                  <MoreHorizIcon  sx={{ color: 'white' }} />
                </IconButton>
              </Box>

              {/* Formulário Dinâmico */}
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1 ,bgcolor: '#1e1e1e'},
                  color: 'white',bgcolor: '#1e1e1e',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  required
                  label="Descrição"
                  value={formData.descricao}
                  onChange={(e) => setFormData({ ...formData, descricao: e.target.value })}
                  InputLabelProps={{ style: { color: 'white' } }}
                  InputProps={{ style: { color: 'white' } }}
                />
                <TextField
                  required
                  label="Valor Mensal"
                  type="number"
                  value={formData.valor_mensal}
                  onChange={(e) => setFormData({ ...formData, valor_mensal: e.target.value })}
                  InputLabelProps={{ style: { color: 'white' } }}
                  InputProps={{ style: { color: 'white' } }}
                />
                {currentCategoria === 'CartaoDeCredito' && (
                  <TextField
                    required
                    label="Parcelas Faltando"
                    type="number"
                    value={formData.quantidade_parcelas}
                    onChange={(e) =>
                      setFormData({ ...formData, quantidade_parcelas: e.target.value })
                    }
                    InputLabelProps={{ style: { color: 'white' } }}
                    InputProps={{ style: { color: 'white' } }}
                  />
                )}
                {currentCategoria !== 'CartaoDeCredito' && (
                  <>
                    <TextField
                      required
                      label="Valor Total pagando hoje"
                      type="number"
                      value={formData.valor_hoje}
                      onChange={(e) =>
                        setFormData({ ...formData, valor_hoje: e.target.value })
                      }
                      InputLabelProps={{ style: { color: 'white' } }}
                      InputProps={{ style: { color: 'white' } }}
                    />
                    <TextField
                      required
                      label="Juros ao Ano"
                      type="number"
                      value={formData.taxa_juros}
                      onChange={(e) =>
                        setFormData({ ...formData, taxa_juros: e.target.value })
                      }
                      InputLabelProps={{ style: { color: 'white' } }}
                      InputProps={{ style: { color: 'white' } }}
                    />
                    <TextField
                    required
                    label="Parcelas Faltando"
                    type="number"
                    value={formData.quantidade_parcelas}
                    onChange={(e) =>
                      setFormData({ ...formData, quantidade_parcelas: e.target.value })
                    }
                    InputLabelProps={{ style: { color: 'white' } }}
                    InputProps={{ style: { color: 'white' } }}
                  />
                  </>
                )}
              </Box>
                <Box sx={{ mt: 2 ,bgcolor: '#1e1e1e'}}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={cadastrarDivida}
                  >
                    Cadastrar
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ ml: 2 }}
                    onClick={() =>
                      setFormData({
                        descricao: '',
                        valor_mensal: '',
                        valor_hoje: '',
                        taxa_juros: '',
                        quantidade_parcelas: '',
                      })
                    }
                  >
                    Limpar
                  </Button>
                </Box>
              </Box>
            </Box>
        </Box>
    );
    
};

export default DashDividas;
