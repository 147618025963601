import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Box, Grid, Button, TextField } from '@mui/material';
import { AuthContext } from '../../services/AuthContext';
import BarraLateral from './TemplatesEstrutura/BarraLateral';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const Configuracoes = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  // Estados para as opções de configuração
  const [repeticaoInteligente, setRepeticaoInteligente] = useState(false);
  const [receberEmails, setReceberEmails] = useState(false);
  const [usuario, setUsuario] = useState('');
  const [email, setEmail] = useState('');
  const [senhaAtual, setSenhaAtual] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmacaoSenha, setConfirmacaoSenha] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) return <div>Carregando...</div>;

  // Função para lidar com a exclusão de todos os dados
  const handleExcluirTudo = () => {
    if (window.confirm('Tem certeza que deseja excluir tudo? Essa ação não pode ser desfeita.')) {
      // Lógica para excluir todos os dados
      console.log('Todos os dados foram excluídos.');
    }
  };

  const handleToggleOption = (setOption, value) => {
    setOption(!value);
  };

  // Função de envio para alteração de email, senha, ou usuário
  const handleSubmit = () => {
    if (novaSenha !== confirmacaoSenha) {
      alert('A nova senha e a confirmação de senha devem ser iguais.');
      return;
    }

    // Lógica para envio de alteração de email, senha ou usuário
    console.log('Alterações enviadas:', { usuario, email, senhaAtual, novaSenha });
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'grey.900', color: 'white' }}>
      <BarraLateral />

      <Box sx={{ flexGrow: 1, p: 4, ml: '160px' }}>
        <Container>
          <Typography variant="h4" sx={{ mb: 4 }}>Configurações</Typography>
          
          {/* Preferências */}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box sx={{ bgcolor: 'grey.800', p: 4, borderRadius: 2 }}>
                <Typography variant="h6">Preferências</Typography>

                {/* Botões de Repetição Inteligente e Receber Emails */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Button
                    variant="contained"
                    color={repeticaoInteligente ? 'success' : 'error'}
                    startIcon={repeticaoInteligente ? <CheckCircleIcon /> : <CancelIcon />}
                    onClick={() => handleToggleOption(setRepeticaoInteligente, repeticaoInteligente)}
                  >
                    {repeticaoInteligente ? 'Repetição Inteligente Ativada' : 'Repetição Inteligente Desativada'}
                  </Button>

                  <Button
                    variant="contained"
                    color={receberEmails ? 'success' : 'error'}
                    startIcon={receberEmails ? <CheckCircleIcon /> : <CancelIcon />}
                    onClick={() => handleToggleOption(setReceberEmails, receberEmails)}
                  >
                    {receberEmails ? 'Receber Emails Ativado' : 'Receber Emails Desativado'}
                  </Button>
                </Box>
              </Box>
            </Grid>

            {/* Alterar informações do usuário */}
            <Grid item xs={12}>
              <Box sx={{ bgcolor: 'grey.800', p: 4, borderRadius: 2 }}>
                <Typography variant="h6">Alterar Informações da Conta</Typography>

                {/* Alterar Nome de Usuário */}
                <TextField
                  label="Novo Usuário"
                  fullWidth
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                  variant="filled"
                  sx={{ mt: 2, bgcolor: 'white', borderRadius: 1 }}
                />

                {/* Alterar Email */}
                <TextField
                  label="Novo Email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="filled"
                  sx={{ mt: 2, bgcolor: 'white', borderRadius: 1 }}
                />

                {/* Senha Atual */}
                <TextField
                  label="Senha Atual"
                  type="password"
                  fullWidth
                  value={senhaAtual}
                  onChange={(e) => setSenhaAtual(e.target.value)}
                  variant="filled"
                  sx={{ mt: 2, bgcolor: 'white', borderRadius: 1 }}
                />

                {/* Nova Senha */}
                <TextField
                  label="Nova Senha"
                  type="password"
                  fullWidth
                  value={novaSenha}
                  onChange={(e) => setNovaSenha(e.target.value)}
                  variant="filled"
                  sx={{ mt: 2, bgcolor: 'white', borderRadius: 1 }}
                />

                {/* Confirmação da Nova Senha */}
                <TextField
                  label="Confirmar Nova Senha"
                  type="password"
                  fullWidth
                  value={confirmacaoSenha}
                  onChange={(e) => setConfirmacaoSenha(e.target.value)}
                  variant="filled"
                  sx={{ mt: 2, bgcolor: 'white', borderRadius: 1 }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ mt: 3, width: '100%', maxWidth: '400px' }}
                >
                  Salvar Alterações
                </Button>
              </Box>
            </Grid>

            {/* Botão Excluir Tudo isolado e destacado em vermelho */}
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleExcluirTudo}
                  sx={{ mt: 2, width: '100%', maxWidth: '400px' }}
                >
                  Excluir Tudo
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Configuracoes;
