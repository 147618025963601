import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Box, Grid, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { AuthContext } from '../../../../services/AuthContext';
import BarraLateral from '../../TemplatesEstrutura/BarraLateral';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

dayjs.locale('pt-br');

const DashDividas = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    // Estados para o mês e ano
    const [mes, setMes] = useState('');
    const [ano, setAno] = useState('');

    // Função para carregar a data do localStorage ou definir a data atual na primeira vez
    const updateDateFromStorage = () => {
        const storedMes = localStorage.getItem('mes');
        const storedAno = localStorage.getItem('ano');

        if (storedMes && storedAno) {
            setMes(storedMes);
            setAno(storedAno);
        } else {
            // Definir a data atual apenas na primeira vez
            const today = dayjs();
            const currentMes = today.format('MM');
            const currentAno = today.format('YYYY');

            setMes(currentMes);
            setAno(currentAno);
            localStorage.setItem('mes', currentMes);
            localStorage.setItem('ano', currentAno);
        }
    };

    // Funções de mudança para mês e ano
    const handleMesChange = (event) => {
        const newMes = event.target.value;
        setMes(newMes);
        localStorage.setItem('mes', newMes); // Salva no localStorage
    };

    const handleAnoChange = (event) => {
        const newAno = event.target.value;
        setAno(newAno);
        localStorage.setItem('ano', newAno); // Salva no localStorage
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        } else {
            updateDateFromStorage(); // Atualiza a data ao montar o componente
        }
    }, [isAuthenticated, navigate]);

    if (!isAuthenticated) return <div>Carregando...</div>;

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <BarraLateral />

            <Box sx={{ flexGrow: 1, bgcolor: 'grey.800', color: 'white', p: 4, ml: '160px' }}>
                <Container>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h4"> Analise</Typography>

                        {/* Seleção de Mês e Ano */}
                        <Box sx={{ display: 'flex', gap: 2, color: 'white' }}>
                            <FormControl sx={{ minWidth: 120, color: 'white' }}>
                                <InputLabel sx={{ color: 'white' }}>Mês</InputLabel>
                                <Select
                                    value={mes}
                                    onChange={handleMesChange}
                                    label="Mês"
                                    sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                                >
                                    {[...Array(12).keys()].map(i => (
                                        <MenuItem key={i + 1} value={String(i + 1).padStart(2, '0')}>
                                            {dayjs().month(i).format('MMMM').charAt(0).toUpperCase() + dayjs().month(i).format('MMMM').slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel sx={{ color: 'white' }}>Ano</InputLabel>
                                <Select
                                    value={ano}
                                    onChange={handleAnoChange}
                                    label="Ano"
                                    sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                                >
                                    {[...Array(5).keys()].map(i => (
                                        <MenuItem key={i} value={String(dayjs().year() - i)}>
                                            {dayjs().year() - i}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Container>

                {/* Botões de navegação */}
                <Box sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
                    <Button variant="contained" color="primary" sx={{ mx: 1 }} onClick={() => navigate('/indicadoresEconomicos')}>
                        Indicadores Economicos
                    </Button>
                    <Button variant="contained" color="primary" sx={{ mx: 1 }} onClick={() => navigate('/listaDeDesejo')}>
                        Lista De Desejo
                    </Button>
                    <Button variant="contained" color="primary" sx={{ mx: 1 }} onClick={() => navigate('/analiseticker')}>
                        Analise Ticker
                    </Button>
                    <Button variant="contained" color="primary" sx={{ mx: 1 }} onClick={() => navigate('/calculadoraFinanceira')}>
                        Calculadora Financeira
                    </Button>
                </Box>

                {/* Layout com as seções */}
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ bgcolor: 'grey.700', p: 3, borderRadius: 2 }}>
                            <Typography variant="h6">Seção 1</Typography>
                            <Typography>Descrição de algum conteúdo ou dado.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ bgcolor: 'grey.700', p: 3, borderRadius: 2 }}>
                            <Typography variant="h6">Seção 2</Typography>
                            <Typography>Descrição de mais algum conteúdo ou dado.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ bgcolor: 'grey.700', p: 3, borderRadius: 2 }}>
                            <Typography variant="h6">Seção 3</Typography>
                            <Typography>Descrição do conteúdo ou dado da Seção 3 de custos.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default DashDividas;
