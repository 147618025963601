import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Box, Grid, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { AuthContext } from '../../../../services/AuthContext';
import BarraLateral from '../../TemplatesEstrutura/BarraLateral';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

dayjs.locale('pt-br');

const DashOrcamento = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const [mes, setMes] = useState('');
    const [ano, setAno] = useState('');

    const updateDateFromStorage = () => {
        const storedMes = localStorage.getItem('mes');
        const storedAno = localStorage.getItem('ano');

        if (storedMes && storedAno) {
            setMes(storedMes);
            setAno(storedAno);
        } else {
            const today = dayjs();
            setMes(today.format('MM'));
            setAno(today.format('YYYY'));
            localStorage.setItem('mes', today.format('MM'));
            localStorage.setItem('ano', today.format('YYYY'));
        }
    };

    const handleMesChange = (event) => {
        const newMes = event.target.value;
        setMes(newMes);
        localStorage.setItem('mes', newMes);
    };

    const handleAnoChange = (event) => {
        const newAno = event.target.value;
        setAno(newAno);
        localStorage.setItem('ano', newAno);
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        } else {
            updateDateFromStorage();
        }
    }, [isAuthenticated, navigate]);

    if (!isAuthenticated) return <div>Carregando...</div>;

    return (
        <Box sx={{ display: 'flex', width: '95.5vw', height: '100vh', overflow: 'hidden', bgcolor: '#1e1e1e', color: 'white' }}>
            <Box sx={{ width: '140px', flexShrink: 0, height: '100vh', bgcolor: '#1e1e1e' }}>
                <BarraLateral />
            </Box>

            <Box sx={{ flexGrow: 1, p: 4, height: '100vh', overflowY: 'auto', bgcolor: '#1e1e1e' }}>
                <Container disableGutters maxWidth={false}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#1e1e1e' }}>
                        <Typography variant="h4" sx={{ color: 'white' }}>Meus Investimentos</Typography>
                        
                        <Box sx={{ display: 'flex', gap: 2, bgcolor: '#1e1e1e' }}>
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel sx={{ color: 'white' }}>Mês</InputLabel>
                                <Select
                                    value={mes}
                                    onChange={handleMesChange}
                                    label="Mês"
                                    sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                                >
                                    {[...Array(12).keys()].map(i => (
                                        <MenuItem key={i + 1} value={String(i + 1).padStart(2, '0')}>
                                            {dayjs().month(i).format('MMMM').charAt(0).toUpperCase() + dayjs().month(i).format('MMMM').slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel sx={{ color: 'white' }}>Ano</InputLabel>
                                <Select
                                    value={ano}
                                    onChange={handleAnoChange}
                                    label="Ano"
                                    sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                                >
                                    {[...Array(5).keys()].map(i => (
                                        <MenuItem key={i} value={String(dayjs().year() - i)}>
                                            {dayjs().year() - i}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Container>

                <Box sx={{ mt: 2, mb: 4, textAlign: 'center', bgcolor: '#1e1e1e' }}>
                    <Button variant="contained" color="primary" sx={{ mx: 1, color: 'white' }} onClick={() => navigate('/investimentos')}>
                        Meus Investimentos
                    </Button>
                    <Button variant="contained" color="primary" sx={{ mx: 1, color: 'white' }} onClick={() => navigate('/comparativos')}>
                        Comparativos
                    </Button>
                    <Button variant="contained" color="primary" sx={{ mx: 1, color: 'white' }} onClick={() => navigate('/importar')}>
                        Importar
                    </Button>
                    <Button variant="contained" color="primary" sx={{ mx: 1, color: 'white' }} onClick={() => navigate('/cripto')}>
                        Cripto
                    </Button>
                    <Button variant="contained" color="primary" sx={{ mx: 1, color: 'white' }} onClick={() => navigate('/metas')}>
                        Metas
                    </Button>
                    <Button variant="contained" color="primary" sx={{ mx: 1, color: 'white' }} onClick={() => navigate('/dashPatrimonio')}>
                        Patrimonio
                    </Button>
                </Box>


                <Grid container item xs={12} sx={{ flexGrow: 1 }}>
    <Box sx={{ bgcolor: '#1e1e1e', p: 3, borderRadius: 2, height: '100%', width: '100%' }}>
        <Typography variant="h6" sx={{ color: 'white' }}>Seção Unica</Typography>
        <Typography sx={{ color: 'white' }}>Descrição do conteúdo ou dado da Seção 3.</Typography>
    </Box>
</Grid>


            </Box>
        </Box>
    );
};

export default DashOrcamento;
