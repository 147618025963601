import React from 'react';
import { Container, Button as MuiButton, Typography, Box, Grid, Card, CardContent, Link, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { WhatsApp as WhatsAppIcon } from '@mui/icons-material';

import dividasImage from '../../../static/dividas.png';
import dashImage from '../../../static/dash.png';

const Home = () => {
  return (
    <>
      {/* Barra superior com Login e Cadastro */}
      <Box
        sx={{
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          bgcolor: 'background.default',
          boxShadow: 1,
          zIndex: 1200,
          py: 1,
        }}
      >
        <Container>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <MuiButton component={RouterLink} to="/login" variant="contained" color="primary" sx={{ mr: 1 }}>
                Login
              </MuiButton>
              <MuiButton component={RouterLink} to="/cadastro" variant="outlined">
                Cadastro
              </MuiButton>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Sobre Nós */}
      <Box sx={{ width: '100%', py: 8, bgcolor: 'background.default', boxShadow: 1 ,
          textAlign: 'center',
          position: 'relative',   // Mantém o footer dentro do fluxo da página
          left: 0,}}>
        <Container>
          <Typography variant="h2" align="center" sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }} gutterBottom>
            Sobre Nós
          </Typography>
          <Typography variant="body1" align="center" sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>
            Estamos no mercado há vários anos, oferecendo consultoria financeira especializada e soluções completas para a gestão das suas finanças.
            Com uma vasta experiência em planilhas e análises financeiras, criamos um sistema único que integra controle de investimentos e orçamento doméstico,
            proporcionando uma visão completa da sua vida financeira.
          </Typography>

          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h4" gutterBottom>
              Monitore suas contas e controle suas dívidas
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={6} md={4}>
                <img src={dividasImage} alt="Controle suas dívidas" style={{ width: '100%', borderRadius: '8px' }} />
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Controle de dívidas de forma prática e intuitiva.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <img src={dashImage} alt="Monitore suas contas" style={{ width: '100%', borderRadius: '8px' }} />
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Dashboard completo para monitorar todas as suas contas.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* Nossos Planos */}
      <Box sx={{width: '100%', py: 8, bgcolor: 'background.default', boxShadow: 1 ,
          textAlign: 'center',
          position: 'relative',   // Mantém o footer dentro do fluxo da página
          left: 0,}}>
        <Container>
          <Typography variant="h2" align="center" sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }} gutterBottom>
            Nossos Planos
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" gutterBottom>
            Gratuito por enquanto
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[
              { title: 'Básico', benefits: ['Acesso ao controle de orçamento'] },
              { title: 'Intermediário', benefits: ['Ferramentas de análise de investimentos'] },
              { title: 'Avançado', benefits: ['Consultoria financeira personalizada'] }
            ].map((plan, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" align="center">
                      {plan.title}
                    </Typography>
                    <ul>
                      {plan.benefits.map((benefit, i) => (
                        <Typography variant="body2" key={i} align="center">
                          {benefit}
                        </Typography>
                      ))}
                    </ul>
                    <MuiButton variant="contained" color="primary" fullWidth component={RouterLink} to="/cadastro">
                      Começar Agora
                    </MuiButton>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Contato */}
      <Box sx={{width: '100%', py: 8, bgcolor: 'background.default', boxShadow: 1,
          textAlign: 'center',
          position: 'relative',   // Mantém o footer dentro do fluxo da página
          left: 0, }}>
        <Container>
          <Typography variant="h4" align="center" gutterBottom>
            Entre em Contato
          </Typography>
          <Box component="form" noValidate>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField label="Nome" fullWidth variant="outlined" placeholder="Seu nome" required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Email" fullWidth variant="outlined" placeholder="Seu email" type="email" required />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Mensagem" fullWidth multiline rows={4} variant="outlined" placeholder="Sua mensagem" required />
              </Grid>
            </Grid>
            <MuiButton variant="contained" color="primary" sx={{ mt: 3 }}>
              Enviar
            </MuiButton>
          </Box>
        </Container>
      </Box>

      {/* Rodapé */}
      <Box
        component="footer"
        sx={{
          width: '100vw',        // Ocupa toda a largura da viewport
          py: 6,
          bgcolor: 'background.default',
          textAlign: 'center',
          position: 'relative',   // Mantém o footer dentro do fluxo da página
          left: 0,                // Alinha com a lateral da viewport
        }}
      >
        <Container>
          <Typography variant="body2" color="textSecondary">
            &copy; 2024 Organizesse. Todos os direitos reservados.
          </Typography>
          <Typography variant="body2">
            <Link component={RouterLink} to="/privacidade" color="inherit">Política de Privacidade</Link> |
            <Link component={RouterLink} to="/termos" color="inherit"> Termos de Uso</Link>
          </Typography>
        </Container>
      </Box>


      {/* Botão de WhatsApp Flutuante */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          bgcolor: 'primary.main',
          borderRadius: '50%',
          boxShadow: 3,
          p: 2,
          zIndex: 1300,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 64,
          height: 64,
        }}
      >
        <a
          href="https://wa.me/5511999999999"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <WhatsAppIcon sx={{ color: 'white', fontSize: '2.5rem' }} />
        </a>
      </Box>
    </>
  );
};

export default Home;
