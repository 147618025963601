import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [nomeUsuario, setNomeUsuario] = useState('');
    const [tempoRestante, setTempoRestante] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const calcularTempoRestanteToken = (exp) => {
        const agora = Math.floor(Date.now() / 1000);
        return exp - agora;
    };

    const refreshToken = async () => {
        try {
            const refresh = localStorage.getItem('refreshToken');
            if (!refresh) throw new Error('Token de refresh não encontrado');
            
            const response = await axios.post('https://organizeseenewersion-2b835fcbcece.herokuapp.com/api/token/refresh/', { refresh });
            localStorage.setItem('token', response.data.access);
            return response.data.access;
        } catch (error) {
            setError('Erro ao atualizar o token. Faça login novamente.');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            navigate('/login');
            console.error(error);
        }
    };

    const [verificacaoRealizada, setVerificacaoRealizada] = useState(false); // Adicione este estado

const verificarAutenticacao = async () => {
    try {
        // Ignora a verificação se já foi realizada anteriormente
        if (!verificacaoRealizada) {
            setVerificacaoRealizada(true);
            return; // Sai da função se a verificação ainda não foi feita
        }

        const token = localStorage.getItem('token');
        if (!token) {
            setIsAuthenticated(false); // Mantenha o estado como não autenticado
            return; // Ignora a verificação se o token não existir
        }

        const response = await axios.get('https://organizeseenewersion-2b835fcbcece.herokuapp.com/api/verificar_token/', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const user = response.data;
        const tempoRestanteToken = calcularTempoRestanteToken(parseJwt(token).exp);
        setTempoRestante(tempoRestanteToken);

        if (tempoRestanteToken <= 60) {
            const novoToken = await refreshToken();
            if (novoToken) {
                setTempoRestante(calcularTempoRestanteToken(parseJwt(novoToken).exp));
            }
        }

        setIsAuthenticated(true);
        setNomeUsuario(user.username);

    } catch (error) {
        setIsAuthenticated(false);
        setError('Erro de autenticação. Faça login novamente.');
        console.error(error);
        navigate('/login');
    }
};

    useEffect(() => {
        verificarAutenticacao(); // Verifica na montagem do componente

        const intervalId = setInterval(async () => {
            await verificarAutenticacao();
        }, 600000); // Verifica a cada 10 minuto

        return () => clearInterval(intervalId);
    }, []); // Remove dependências para que verifique continuamente

    return (
        <AuthContext.Provider value={{ isAuthenticated, nomeUsuario, error, tempoRestante, setIsAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

const parseJwt = (token) => {
    return JSON.parse(atob(token.split('.')[1]));
};
