import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const BarraLateral = () => {
    const [nomeUsuario, setNomeUsuario] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const storedNomeUsuario = localStorage.getItem('nomeUsuario');
        if (storedNomeUsuario) {
            setNomeUsuario(storedNomeUsuario);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('nomeUsuario');
        navigate('/login');
    };

    return (
        <Box
            sx={{
                width: 180, // Aumentei a largura para evitar quebra de layout
                bgcolor: '#1e1e1e',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'fixed',
                height: '100vh',
                left: 0,
                top: 0,
                boxShadow: 3,
                overflow: 'hidden',
                p: 2,
            }}
        >
            {/* Nome do usuário */}
            <Typography variant="h6" noWrap sx={{ color: 'white', mb: 4,bgcolor: '#1e1e1e', textAlign: 'center' }}>
                {nomeUsuario || 'Usuário'}
            </Typography>

            {/* Botões de navegação centralizados */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1, gap: 2, bgcolor: '#1e1e1e' }}>
                <Button component={Link} to="/dashOrcamento" fullWidth sx={{ color: 'white' }}>
                    Orçamento
                </Button>
                <Button component={Link} to="/investimentos" fullWidth sx={{ color: 'white' }}>
                    Investimentos
                </Button>
                <Button component={Link} to="/indicadoresEconomicos" fullWidth sx={{ color: 'white' }}>
                    Mercado
                </Button>
                <Button component={Link} to="/configuracoes" fullWidth sx={{ color: 'white' }}>
                    Configurações
                </Button>
            

            {/* Botão "Sair" ao final */}
            <Button
                onClick={handleLogout}
                fullWidth
                sx={{
                    bgcolor: '#1e1e1e',
                    textAlign: 'end',
                    color: 'white',
                    mt: 4,
                    '&:hover': { bgcolor: '#333' },
                }}
            >
                Sair
            </Button>
            </Box>
        </Box>
    );
};

export default BarraLateral;
