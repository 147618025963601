import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../services/AuthContext';

const PrivateRoute = ({ element }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  // Exibe um carregamento enquanto a autenticação está sendo verificada
  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
